<template>
  <CRow>
    <CCol col="12">
      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit email') + ` id: ${form.id}` : $t('Create email') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event.target.name || 'test')">
            <ACard>
              <AMultiSelect :horizontal="{ input: 'col-sm-5', label: 'col-sm-3' }" label="Property" v-model="form.property_id" :options="lists.properties" :isValid="isValid('property_id')" :errors="getErrors('property_id')" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="email" label="Email" type="email" v-model="form.email" :isValid="isValid('email')" :errors="getErrors('email')" />
              <ACheckBox label="Ignored" v-model="form.is_ignored" />
              <ACheckBox label="Enabled" v-model="form._status" />
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" color="info" :disabled="!status.is_valid" @click="submit">{{ $t('Save') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
//import * as utils from '@/app/_utils/global-utils'
import formMixin from '@/app/views/_mixins/form-mixin'
import { DEBUG } from '@/config/config'

export default {
  name: 'emailForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, _status: true },

      // Extra
      lists: {},
      status: {
        is_valid: true
      }
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/gmail/emails' + (self.form.id ? `/${self.form.id}/edit` : '/create'))
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onMonthChanged(type, month) {
      console.log(type, month)
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.email ? data.email : { id: 0, _status: true }
      this.lists = data._lists || {}

      this.parseView()

      this.validators = {
        main: data._validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)
    },

    // Parsing
    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-info', content: this.$t('Save'), disabled: !this.status.is_valid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'email']: options })
    },

    // SUBMIT

    submit() {
      if (!this.status.is_valid) return

      this.setErrors({})
      this.$v.form.$touch()

      const alert = `There was an error ${this.form.id ? 'saving' : 'creating'} the property.`

      if (this.$v.form.$error) {
        this.locateErrors(alert)
        return
      }

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/gmail/emails', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/super/gmail/emails/${response.data.email.id}/edit`).catch(() => {})
          self.showAlert(`email ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            self.showAlert(alert, 'danger')
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(alert, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
